body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.auth-background {
  height: 100vh;
  background-color: #e5e5e5;
  padding: 0px !important;
  overflow: hidden;
}

.auth-background img {
  width: 100%;
}

.navbar {
  background-color: #ffffff !important;
  padding-left: 30px;
  padding-right: 30px;
}

.formInput .text-muted {
  font-size: 12px;
  color: #a0aec0 !important;
}

.text {
  color: "#787878";
}

.passwordInputWrapper {
  position: relative;
  width: 100%;
}

.form-floating {
  width: 100%;
}

.loginForm .title {
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  text-align: center;
  width: fit-content;
  border-bottom: 2px solid #e2e8f0;
  padding: 0 15px 15px;
  margin-bottom: 10px;
}

.formButton {
  background: #4080ff;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  border: none;
}

.sidebarHeader {
  padding: 50px;
  background-color: #e2e8f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: #f7fafc !important;
}

.iconText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobileNav {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #4080ff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
}

.page {
  padding: 0px !important;
  overflow: auto;
  background-color: #f5f5f5;
  position: relative;
}

.pageWrapper {
  display: flex;
  flex-direction: row;
}

.sidebarContainer {
  height: 100%;
}

.children {
  flex: 1;
}

.stack {
  padding-left: 20px;
  border-left: 1px solid #4a5568;
}

.dashcard {
  border: 1px solid #c4d6ea;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.dashcard > .bottom {
  margin-top: 15px;
}

.dashcard > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.customCard {
  background-color: #fdfefe;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dataAllocationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.battery {
  display: flex;
  border: 1px solid #a3a8ba;
  padding-left: 1.5px;
  padding-right: 1.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1;
  flex-direction: row;
  border-radius: 4px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.charge {
  height: 60px;
  background-color: #00bf91;
  border-radius: 4px;
  flex: 0.1;
  margin-left: 2px;
  margin-right: 2px;
}

.propicHolder {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #c4c4c4;
  margin-right: 10px;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.messageBlock {
  height: 80vh;
  overflow: auto;
  position: relative;
}

.messageHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
}

.countBagde {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #4080ff;
  color: #ffffff;
  font-weight: 500;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.list-group-item {
  padding: 0 !important;
  margin-bottom: 8px !important;
  background: transparent !important;
  border-radius: 4px !important;
  border: none !important;
}

.messageBox {
  background: #f9f9f9;
  padding: 15px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.user .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.mediaContainer > img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}

.list-tans {
  padding: 15px 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px #f8f8f8, 0px -1px 0px #f8f8f8;
  cursor: pointer;
}

.statusCard {
  padding: 5px 15px;
  border-radius: 2px;
  background-color: #00bf91;
  color: #4a5568;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-left: 20px;
}

.courseCard {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  height: 100;
  overflow-y: hidden;
}

.courseCard:hover {
  border: 1px solid #4080ff;
}

.courseCard > img {
  width: 100%;
}

.courseCard > .content {
  padding: 10px;
}

.courseCard > .content > .text {
  font-weight: bolder;
  font-size: 15px;
  color: #4a5568;
  margin-bottom: 10px;
}

.courseCard > .content > .stats > .stat > .title {
  font-size: 10px;
  color: #a3a8ba;
}

.courseCard > .content > .stats > .stat > .value {
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #4a5568;
}

.courseCard > .content > .stats > .stat {
  margin-right: 10%;
  margin-bottom: 10px;
}

.courseCard > .content > .stats {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}

.courseCard > .content > .stats > .stat > .green {
  color: #00bf91;
}

.courseCard > .content > .stats > .stat > .blue {
  color: #6666ea;
}

.logo {
  width: 150px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #4080ff !important;
  font-weight: 500 !important;
}

.search {
  position: relative;
  flex: 1;
  /* width: 70%; */
}

.search > input {
  background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
}

.search > div {
  position: absolute;
  top: 0;
  right: 10px;
  color: #4080ff;
  cursor: pointer;
  font-size: 25px;
}

.nav-tabs .nav-link {
  border: none;
  color: #a3a8ba;
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f5f5f5 !important;
  border-bottom: 2px solid #4080ff !important;
  border: none;
  color: #4080ff !important;
}

.overview,
.publishedCourses,
.advertisement {
  margin-top: 20px;
}

.instructor {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.instructor:hover {
  border: 1px solid #4080ff;
}

.rate {
  display: flex;
  flex-direction: row;
}

.underline {
  background-color: #d69e2e;
  height: 2px;
  border-radius: 1px;
}

.instructorList {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  align-items: center;
}

.noResultsCard {
  background: #f0f0f0 !important;
  border: 1px solid #dbdbdb !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 50px;
}

.noResultsCard .card-body .card-text {
  font-size: 16px;
  text-align: center;
  color: #718096;
}

.noResultsCard .card-body .card-title {
  font-size: 22px;
  text-align: center;
  color: #4a5568;
  margin-bottom: 20px;
}

.adsDetails {
  background: #fdfefe;
  border-radius: 6px;
  margin-top: 20px;
}

.adsDetails .imageWrapper > img {
  width: 100%;
}

.adsDetails .imageWrapper {
  position: relative;
}

.adsDetails .imageWrapper .mask {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 6px 6px 0px 0px;
}

.adsDetails .imageWrapper .mask .text {
  text-align: center;
  color: #fdfefe;
  font-weight: 500;
  font-size: 24px;
}

.adsDetails > .content {
  padding: 20px 30px !important;
}

.adsDetails > .content .text {
  font-weight: 500;
  font-size: 24px;
  color: #4a5568;
}

.ad .stat > .title {
  font-size: 12px;
  color: #a3a8ba;
}

.ad .stat > .value {
  font-weight: 500;
  font-size: 14px;
  color: #4a5568;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.ad .stat {
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: right;
}

.course .stat {
  text-align: left;
}

.course .stat > .assignment {
  color: #4080ff;
}

.ad .stat > .green {
  color: #00bf91;
}

.messageReply {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  bottom: -2px;
  width: 100%;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  /* position: absolute; */
}

.messageReply textarea {
  background-color: #f5f5f5 !important;
  border: none !important;
  resize: none;
}

.messageReply label {
  color: #718096 !important;
  font-size: 14px !important;
}

.attach {
  margin-right: 10px;
}

.send {
  width: 45px;
  height: 45px;
  font-size: 12px;
  border-radius: 22.5px;
  background-color: #4080ff;
  color: #ffffff;
  font-weight: 500;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
}

.message .avatar {
  width: 21px;
  height: 21px;
  background-color: #c4c4c4;
  border-radius: 10.5px;
  margin-right: 8px;
}

.chatBot {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 6px;
  color: #718096;
  width: 60%;
  font-size: 12px;
}

.reverse {
  flex-direction: row-reverse;
}

.reverse .avatar {
  margin-right: 0;
  margin-left: 8px;
}

.reverse .chatBot {
  background-color: #4080ff;
  color: #ffffff;
}

.postPage {
  padding: 0px !important;
  overflow: auto;
  background-color: #f5f5f5;
  height: 100vh;
  position: relative;
}

.postPageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  background-color: #f0f0f0;
}

.postPageHeader .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.progress {
  height: 3px !important;
  border-radius: 0px !important;
}

.postPage > .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.input {
  height: 57px;
  background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 2px;
}

.input > input {
  background-color: #f5f5f5 !important;
  border: none !important;
}

.input > button {
  color: #4080ff;
  border-color: #4080ff;
  font-size: 16px;
  border-radius: 4px !important;
}

.postPage .actionsWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 10%;
  padding-bottom: 5%;
  flex-direction: row;
  align-items: center;
}

.actionsWrapper {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.postPage .actionsWrapper button {
  max-width: 200px;
}

.formInput input {
  height: 50px;
  min-width: 250px;
  background-color: #f5f5f5;
  border: 1px solid #a0aec0;
}

.formInput input::placeholder {
  color: #a0aec0;
  font-size: 14px;
}

.formInput label {
  color: #4a5568;
  font-size: 14px;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thumbnail > .preview {
  width: 146px;
  height: 146px;
  background: #c4c4c4;
  border-radius: 9px;
}

.thumbnail > .text {
  font-size: 14px;
  color: #4a5568;
}

.floatingCard {
  background-color: #ffffff;
  border: 1px solid #4080ff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 2;
  padding: 15px 20px 15px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultsCard {
  height: 590px;
  overflow: auto;
}

.questionCard {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.questionCard > .iconText {
  position: absolute;
  top: 20px;
  right: 20px;
}

.questionCard > .iconText > .text {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}

.roundButton {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #4080ff;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.shadow {
  background: #ffffff;
  border: 1px solid #4080ff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.shadow button {
  background: linear-gradient(258.94deg, #4a5568 2.59%, #2e3645 100%);
}

.floatingCard > .user .text {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #4a5568 !important;
}

.float {
  position: absolute;
  top: 30px;
  right: 20px;
}

.radioCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0px 15px;
}

.radioCard.active {
  border: 1px solid #4080ff;
}

.radioCard > .text {
  margin-left: 20px;
  font-size: 16px;
  color: #4a5568;
}

.stackCard {
  display: flex;
  flex-direction: column;
  color: #4a5568;
  text-align: left;
}

.stackCard .title {
  font-size: 16px;
}

.stackCard .amount {
  font-size: 25px;
  color: #4a5568;
}

.stackCard .caption {
  font-size: 14px;
  color: #a3a8ba;
}

.dotList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.dotList .dot,
.dot {
  width: 15px;
  height: 15px;
  background-color: #4a5568;
  border-radius: 7.5px;
  margin-right: 10px;
}

.dotList .text {
  font-size: 16px;
  color: #4a5568;
}

.paymentFormInput {
  border: 1px solid #a3a8ba;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 62px;
  border-radius: 8px;
  padding: 0px 15px;
  color: #4a5568;
}

.paymentFormInput input {
  border: none;
}

.doughnut {
  position: relative;
  width: 200px;
}

.relative {
  position: relative;
}

.doughnut .text {
  position: absolute;
  top: 90px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #a3a8ba;
}

.rateCard {
  padding: 15px;
  background: #fdfefe;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 4px;
}

.rateCard:hover {
  border: 1px solid #4080ff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.err {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 5px;
}

.draftPlaceholder {
  background-color: #c4c4c4;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
}

.notificationCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #e2e8f0;
  margin-top: 10px;
}

.small-alert {
  font-size: 14px;
  padding: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}

.new-course-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 350px;
  min-height: 350px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: border 0.9s ease;
  border-radius: 6px;
}

.new-course-card:hover {
  border: 2px solid #6666ea;
}

.new-course-card .ribbon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff0000;
  color: #fff;
  padding: 2.5px 5px;
  overflow: hidden;
}

.new-course-card-image {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.new-course-card-body {
  padding: 10px;
}

.new-course-card-body .new-course-card-title {
  min-height: 30px;
  max-width: 100%;
  overflow: hidden;
}
.new-course-card-title h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #4a5568;
}

.new-course-card-body .new-course-card-info {
  display: flex;
  justify-content: space-around;
}

.new-course-card-body .new-course-card-info .info {
  max-height: 60px;
  flex-basis: 45%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.new-course-card-body .new-course-card-info .info small {
  font-size: 10px;
  color: #a3a8ba;
}
.new-course-card-body .new-course-card-info .info p {
  margin-top: 0px;
  color: #4a5568;
  font-size: 15px;
  color: #4a5568;
}
